import React from "react";
import { Link } from "react-router-dom";
import Hero from "./../hero/hero";
import "./home.scss";

export default function Home(props) {
  return (
    <div>
      <Hero
        h1="Software, done right"
        h2="From concept to creation, we ensure that your software is built to last"
      />
      <div className="container">
        <div className="row my-5">
          <div className="col-sm quotes font-italic flex-column flex-nowrap justify-content-center d-none d-md-flex">
            <h4 className="m-3 p-2 text-center">
              "I just need extra resource to deliver my project"
            </h4>
            <h4 className="m-3 p-2 text-center">
              "I need an expert to teach my team"
            </h4>
            <h4 className="m-3 p-2 text-center">
              "I know what I'd like, I just don't know how"
            </h4>
          </div>

          <div className="col-sm">
            <h2 className="text-primary m-3">
              Our software consultants can help
            </h2>
            <ul className="quotes">
              <li className="m-1 p-1">
                Design & Implement software solutions to address business
                problems
              </li>
              <li className="m-1 p-1">
                Identify technological barriers and their solutions
              </li>
              <li className="m-1 p-1">
                Identify where and how technology could be applied to drive
                efficiency
              </li>
              <li className="m-1 p-1">
                Develop your in-house skills to support your business
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row bg-light">
        <div className="container">
          <div className="row my-4">
            <div className="col-sm m-4 mx-sm-0">
              <h2 className="text-primary">Understanding is key</h2>
              <p>
                The key to our success is understanding our client, their
                business and the challenges they face.
              </p>
              <p>
                We help transform the technology they use and the way they use
                it.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="container">
          <div className="row my-4">
            <div className="col-sm m-4 mx-sm-0">
              <h2 className="text-primary">The Goal</h2>
              <p>
                Our goal is to provide our clients with the tools they need to
                succeed in driving profit gains.
              </p>
              <p>
                We won’t make you reliant on us and we won’t hit you with any
                hidden fees.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row bg-light">
        <div className="container">
          <div className="row my-5">
            <div className="col-sm m-4 mx-sm-0">
              <h2 className="text-primary">Our Process</h2>
              <p>
                Our process has been developed with our clients in mind; focusing on delivering maximum R.O.I.
              </p>
              <p>
                By focusing on delivering a minimum viable product we allow our clients to revise their requirements and produce the product they need for the lowest possible cost.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
